import { Box, Container, Card, Button, Grid, TextField, styled, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from 'src/contexts/Auth/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const LoginWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);
function Login() {

  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const sampleLocation = useLocation()

  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')

  const [showSenha, setShowSenha] = useState(false);
  const handleClickShowSenha = () => setShowSenha((show) => !show);
  const handleMouseDownSenha = (event) => {
    event.preventDefault();
  }

  useEffect(() => {
    const getUsuario = async () => {
      const usuarioInfo = await localStorage.getItem('authToken')
      let hasUsuario = JSON.parse(usuarioInfo || '{}')
      if (Object.keys(hasUsuario).length > 0) {
        if (sampleLocation.pathname === "/") {
          navigate('/inicio')
        }
      } else {
        navigate('/')
      }
    }
    getUsuario()
  }, [])

  async function handleLogin(e) {
    e.preventDefault()

    if (!email || !senha) {
      toast.error("Preencha os campos necessários para efetuar o login.")
    }

    const respostaLogin = await auth.signin(email, senha)
    if (respostaLogin == true) {
      navigate('/inicio');
    } else {
      toast.error("Email ou senha incorretos.")
    }
  }

  return (
    <LoginWrapper sx={{ background: 'rgb(17, 25, 42)', height: '100%' }}>
      <ToastContainer />
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" py={5} alignItems="center">
          <Card sx={{ pt: 10, pb: 10, mb: 10, borderRadius: 2, background: '#FFFF', width: { xs: "100%", sm: "70%", md: "40%", lg: "40%" } }}>
            <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
              <img
                style={{ width: '25%' }}
                src="/static/images/logo/logo.png"
                className=""
                alt="Logo"
              />
              <Grid
                justifyContent="center"
                alignItems="center"
                container
              >
                <Grid item mx="auto">
                  <TextField
                    sx={{ background: 'white',borderRadius: '10px' }}
                    type="text"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />

                  <FormControl fullWidth sx={{ mt: '16px !important', mb: '8px !important', m: '0px' }}>
                    <InputLabel htmlFor="outlined-adornment-senha" required>Senha</InputLabel>
                    <OutlinedInput
                      sx={{ background: 'white' }}
                      required
                      value={senha}
                      onChange={(e) => setSenha(e.target.value)}
                      id="outlined-adornment-senha"
                      type={showSenha ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Altere a visibilidade"
                            onClick={handleClickShowSenha}
                            onMouseDown={handleMouseDownSenha}
                            edge="end"
                          >
                            {showSenha ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Senha"
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    size="large"
                    sx={{ mt: "1rem" }}
                  >
                    Logar
                  </Button>

                </Grid>
              </Grid>
            </Container>
          </Card>
        </Box>

      </Container>
    </LoginWrapper>
  );
}

export default Login;
