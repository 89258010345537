import { createContext } from 'react'
import { useEffect, useState } from "react";
import { api } from "../../services/api";

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [infoLogin, setInfoLogin] = useState(null);
    const [isLoadingInfoAcesso, setIsLoadingInfoAcesso] = useState(true)

    useEffect(() => {
        const getUsuario = async () => {
            const usuarioInfo = await localStorage.getItem('authToken')
            let hasUsuario = JSON.parse(usuarioInfo || '{}')

            if (Object.keys(hasUsuario).length > 0) {
                api.defaults.headers.common['Authorization'] = `Bearer ${hasUsuario.token}`

                setInfoLogin({
                    id: hasUsuario.id,
                    nome: hasUsuario.nome,
                    email: hasUsuario.email,
                    token: hasUsuario.token,
                })
            }
        }

        getUsuario()

    }, [])

    const signin = async (email, senha) => {
        try {
            //login do gestor
            const response = await api.post('/logingestor', {
                email,
                senha
            })
            if (response.data) {
                const { id, nome, token } = response.data

                if (token) {

                    const data = {
                        ...response.data
                    }

                    await localStorage.setItem('authToken', JSON.stringify(data));

                    api.defaults.headers['Authorization'] = `Bearer ${token}`

                    setInfoLogin({
                        id,
                        nome,
                        email,
                        token,
                    })

                    return true

                } else {

                    return response.data.errors.msg

                }
            }
            return false

        } catch (error) {
            console.log(error)
        }
    }

    const signout = async () => {
        setInfoLogin('')
        localStorage.clear()
        return true
    }

    const detail = async () => {

        const usuarioInfo = await localStorage.getItem('authToken')

        let hasUsuario = JSON.parse(usuarioInfo || '{}')

        if (hasUsuario.id > 0) {
            api.defaults.headers['Authorization'] = `Bearer ${hasUsuario.token}`

            try {
                const response = await api.get(`/gestor/${hasUsuario.id}`);
                if (response.data) {
                    setIsLoadingInfoAcesso(false)
                }
            } catch (error) {
                setInfoLogin('')
                localStorage.clear()
                return true
            }

        } else {
            setIsLoadingInfoAcesso(false)
        }

    }

    detail();

    if (isLoadingInfoAcesso) {
        return <></>
    }

    return (
        <AuthContext.Provider value={{ infoLogin, signin, signout }}>
            {children}
        </AuthContext.Provider>
    );
}