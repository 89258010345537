import { useContext } from "react";
import Login from "src/pages/Login";
import { AuthContext } from "./AuthContext";

export const RequireAuth = ({ children }) => {
    const auth = useContext(AuthContext);

    if (!auth.infoLogin) {
        return <Login />;
    }

    return children;
}